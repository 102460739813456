import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
// import {Stripe} from 'stripe.js'
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from '../components/stripeElements'
import '../components/CheckoutForm.css'
import { navigate } from 'gatsby'
const pk_live = 'pk_live_RxPqNV8wJES88O9PlWkRbiqU'
// const pk_test = 'pk_test_47IIvaiJ7FanzzHsVr7vaEKH'

const Register = ({ location }) => {


  let [cost, setCost] = useState(1);
  let [course, setCourse] = useState('');
  let [date, setDate] = useState('');
  let [images, setImages] = useState('');
  let [acceptedTC, setAcceptedTC] = useState(true)
  let [cryptoID, setCryptoID] = useState(null)


  const loadScript = src =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = src
      script.addEventListener('load', () => {
        resolve({ successful: true })
      })
      script.addEventListener('error', event => {
        reject({ error: event })
      })
      document.head.appendChild(script)
    })


  const [stripeLoaded, setStripeLoaded] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      const result = await loadScript('https://js.stripe.com/v3/')
      setStripeLoaded(result.successful)
    }
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps 





  useEffect(() => {
    // setStripe(window.Stripe(pk_test))
    if (!location.state) { navigate('/') } else {
      setDate(location.state.date)
      setCourse(location.state.course)
      setCost(location.state.cost)
      setImages(location.state.images)
      setCryptoID(location.state.cryptoID)
    }
  }, []);

  useEffect(() => {
    cost == 580 ? localStorage.setItem('bubblegum', 5800.0) : localStorage.setItem('bubblegum', cost)
  }, [cost])


  return <div className="white">
    <h1>First step to join us</h1>
    <p>You will soon be on a journey of learning a stack of technical tools and skills to help you achieve your goals.</p>
    <h3>Selected course: {course}</h3>
    <h3>Selected starting date: {date}</h3>
    {cost === 580 ? <>
      <h3>Registration fee: {cost}€</h3>
      <p>Full tuition: 5800€</p>
      <p>After signing up we will get back to you right after with a student's form to fill out and proceed with creating your account, preparing student contract and sending all the materials.</p>
      <p>If you are eligible for any of the scholarships we offer please select it in that student's form and the final tuition would be calculated accordingly.</p>
    </>
      : <>
        <h3>Registration fee: {cost}€</h3>
        <p>After signing up we will get back to you right after to proceed with creating your account, preparing student contract and sending all the materials.</p>
      </>
    }
    <div style={{ marginBottom: '1em' }} >
      <p>By continuing you confirm that you've read and accepted <a href='https://barcelonacodeschool.com/about-us/terms-and-conditions/' target='blank'>Terms and Conditions</a></p>
    </div>
    {/* <div style={{marginBottom:'1em'}} >
<label><h2>
<input
id='tANDc'
type="checkbox" 
style={{width:'auto',margin:'auto .5em 0 0 '}} 
checked={acceptedTC}
onChange={()=> setAcceptedTC(!acceptedTC)}
/>
Please confirm that you've read and accepted <a href='https://barcelonacodeschool.com/about-us/terms-and-conditions/'  target='blank'>Terms and Conditions</a> before continue
</h2></label>
</div> */}


    {(cost > 1 && stripeLoaded) ? <StripeProvider apiKey={pk_live} >
      <Elements>
        <CheckoutForm amount={cost} description={`${course + ' ' + date}`} acceptedTC={!acceptedTC} images={images} />
      </Elements>
    </StripeProvider> : null}

    {cryptoID &&
      <>
        <h2 style={{ 'textAlign': 'center', 'margin': '1em 0' }}>Or</h2>
        <a
          href={`https://commerce.coinbase.com/checkout/${location.state.cryptoID}`} target='_blank' rel='noopener noreferrer'>
          <button>Sign up with crypto</button>
        </a>
      </>
    }

  </div>
}





export default Register
