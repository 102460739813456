import React from 'react'
import revStarPart from "../pics/revStarPart.png"
import revStar from "../pics/revStar.png"

export default function Ratings() {
    const starPart = <img src={revStarPart} alt='star' style={{ 'margin':'0','verticalAlign': 'baseline', 'height': '.8em' }} />
	const star = <img src={revStar} alt='star' style={{ 'margin':'0','verticalAlign': 'baseline', 'height': '.8em' }} />
    
  return (
    <div className='whiteCard flex1200' style={styles.reviewsContainer}>
    <h3 style={styles.review}>Google Reviews: 4.9 {star}{star}{star}{star}{star}</h3>
    <h3 style={styles.review}>SwitchUp: 4.8 {star}{star}{star}{star}{star} </h3>
    <h3 style={styles.review}>CourseReport: 4.7 {star}{star}{star}{star}{starPart}</h3>
</div>
  )
}

const styles = {
	review: {
		display: 'inline-block',
		wordBreak: 'none',
		margin: '0',
		width:'fit-content',
		minWidth: 'max-content'
	},
	reviewsContainer: {
		'display': 'flex',
		'justifyContent': 'space-between',
		'flexWrap': 'wrap',
		'alignItems': 'center',
	}

}