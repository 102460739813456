import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../components/checkout';

import ReactCourse from '../pics/react_crash_course.jpg'
import Instalments from '../components/instalments.js'

import Contacts from '../components/contactform';
import Covid from '../components/covid';

const ReactCrashCourse = () => {

	let [courses, setCourses] = useState({
		durationdays: 5,
		durationweeks: 1,
		fulldesc1: "1-week course, in-person, in English, full-time, Monday–Friday, 9:00 to 18:00",
		fulldesc2: "Registration payment 850€",
		fullprice: 850,
		image: "https://barcelonacodeschool.com/files/pics/react_crash_course.jpg",
		priceoptions: [],
		shortdesc: "Front-end web development with React.js!",
		signupprice: 850,
		startdates: ['January 27, 2025', 'April 14, 2025','June 23, 2025'],
		starttime: "9:00",
		tag: "reactCrash",
		title: "React.js Crash Course",
	})

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/reactCrash')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])

	let renderCourses =()=> {
		return courses.startdates.map((date,i)=>(
			<div key={i}className="whiteCard bottomSpace flex300" >
			<span>
			<h2>{date}</h2>
			<h4>{courses.title}</h4>
			<p>{courses.shortdesc}</p>
			{date.includes("January 29, 2024")&& <h2 className='highlighter'>Group is full!</h2>}
			</span>
			<span>
			<p>{courses.fulldesc1}</p>
			<p>{courses.fulldesc2}</p>
			{date.includes("January 27, 2025")?<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image], cryptoID: '91ebb7e4-9b8c-478b-963f-9690215533d9' }}>
			<button style={{'marginBottom': '1em'}}>1 place left</button>
			</Link>:<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image], cryptoID: '91ebb7e4-9b8c-478b-963f-9690215533d9' }}>
			<button style={{'marginBottom': '1em'}}>Sign up</button>
			</Link>}
			<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
			</span>
			</div>
			))
	}

	return <div className="flexContent"
	>
	<Helmet

	title="React.js Crash Course in Barcelona Code School"
	meta={[
		{ name: 'description', content: 'React.js Crash Course in Barcelona Code School is a week-long intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript. This course is a part of the Web / Mobile Development Bootcamp and could be taken separately as a module.' },
		{ name: 'keywords', content: 'programming with react course, react course, web development with react in barcelona' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
		{property: "og:title", content:"React.js Crash Course in Barcelona Code School"},
		{property: "og:description", content: "A week-long intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript . This course is a part of the Web / Mobile Development Bootcamp and could be taken separately as a module."},
		{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/react_crash_course.jpg"},
		{property: "og:url", content: "https://barcelonacodeschool.com/reactjs-crash-course/"},
		{name: "twitter:card", content: "summary_large_image"}
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>
			<h1 className='transparentContainer flex1200'>React.js Crash Course in Barcelona Code School</h1>

			<img 
			className='banners flex1200 offsetImage'
			src={ReactCourse} 
			alt='React.js Crash Course in Barcelona Code School'/>


			<h2 className='transparentContainer flex450'>Front-end web development with React.js!</h2>




			<div className='socialsharebuttons transparentContainer'>
			<div className='socialshare'>
			<a className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='React Intensive Course in Barcelona Code School'&url=https://barcelonacodeschool.com/reactjs-crash-course/"
			data-size="large">
			<button>tweet</button>
			</a>
			</div>

			<div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
			<a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/reactjs-crash-course/" className="fb-xfbml-parse-ignore">
			<button>facebook</button>
			</a>
			</div>
			</div>
			

<div className='transparentContainer'>
						<article className='whiteCard flex300'>
						<ul className='neonList'>
						<li><span className='highlighter'>Intensive in-person</span> hands-on training</li>
						<li><span className='highlighter'>1 week</span> of hardcore React coding</li>
						<li><span className='highlighter'>Daily classes</span> 9:00 to 18:00</li>
						<li>The course is taught in <span className='highlighter'>English</span></li>
						<li>From zero to 👸 and 🤴</li>
						<li>15% theory / 85% practice / 100% efficiency</li>
						</ul>
						</article>
			</div>			



			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul className='neonList'>
			<li>Overall concept</li>
			<li>JSX syntax</li>
			<li>create-react-app</li>
			<li>Structuring React app</li>
			<li>Components-based approach and reusability</li>
			<li>Class and function components</li>
			<li>Passing data between components with props</li>
			<li>Using state and state management</li>
			<li>Shared state management</li>
			<li>Events and event handlers</li>
			<li>Lifecycle methods</li>
			<li>Hooks</li>
			<li>Async requests</li>
			<li>Promises</li>
			<li>Conditional rendering</li>
			<li>React router</li>
			<li>Metadata in React</li>
			<li>Using external components</li>
			<li>Production build, deployment</li>
			</ul>
			</div>
			</article>



			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<p>Solid understanding of JavaScript logic and syntax; functions, loops, conditionals, arrays, objects.</p>
			<p>Good knowledge of HTML/CSS: DOM, structuring HTML, CSS selectors, Grid, Flexbox.</p>
			</div>
			</article>



			<article className='transparentContainer flex225'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<p>After this course you will have a strong understanding of the <strong>logic</strong>, <strong>syntax</strong> and <strong>usage</strong> of ReactJS. </p>
			<p>You will be able to build React apps from scratch and have progress further into the depths of React or any other front-end JS library/framework such as Angular, Vue, Ember or anything else.</p>
			</div>
			</article>


			<article className='transparentContainer flex225'>
			<h2>A Wise quote</h2>
			<div className='whiteCard'>
			<p>"Don’t worry if it doesn’t work right. If everything did, you’d be out of a job." </p>
			<p>– Mosher’s Law of Software Engineering</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>850€</h2>
			<p>100% is paid upon registration.</p>
			<Instalments/>
			</div>
			</article>


			<article className="transparentContainer flex1200 flexContent">
			<h2>Access</h2>
			<div className='flexContent'>
			{renderCourses()}
			</div>
			</article>

			<article className='flex450'>
			<Contacts title={'Inquiry about the course'} subject={'Inquiry about React.js Crash Course'} />
			</article>
			</div>

		}

		export default ReactCrashCourse
