import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import PartnersLogos from "../components/partnerLogos.js"

import { Stripe } from "stripe.js"

import Contacts from "../components/contactform"
import Inperson from "../pics/deep_learning_bootcamp.jpg"
import OurStudents00 from "../pics/students/barcelona_code_school_students_00.jpg"
import Instalments from '../components/instalments.js'
import Minerva from "../pics/team/minerva_singh.jpeg"



const MLBootcamp = () => {
	let courses = [{
		durationdays: 91,
		durationweeks: 13,
		fulldesc1: `9-week course, in-person, in English, full-time.`,
		fulldescOnline: "13-week course, online, mentored, in English, part-time.",
		tuition: '7900€',
		tuitionOnline: '3950€',
		fulldesc2: "Registration prepayment 900€.",
		fullprice: 3950,
		image: "https://barcelonacodeschool.com/files/pics/deep_learning_bootcamp.jpg",
		priceoptions: [],
		shortdesc: "Become a ML/AI Engineer in 13 weeks",
		shortdescOnline: "Become a ML/AI Engineer in 13 weeks",
		signupprice: 900,
		startdate: "April 19, 2021",
		startdates: [
			{ date: 'February 3 – May 2, 2025', format: 'ONLINE' },
			{ date: 'March 3 – May 30, 2025', format: 'ONLINE' },
			{ date: 'April 7 – July 4, 2025', format: 'ONLINE' },
			{ date: 'May 5 – August 1, 2025', format: 'ONLINE' },
			{ date: 'June 2 – August 29, 2025', format: 'ONLINE' },
			// { date: 'July 7 – October 3, 2025', format: 'ONLINE' },
			// { date: 'August 4 – October 31, 2025', format: 'ONLINE' },
			// { date: 'September 1 – November 28, 2025', format: 'ONLINE' },
			// { date: 'October 6 – January 2, 2026', format: 'ONLINE' },
			// { date: 'November 3 – January 30, 2026', format: 'ONLINE' },
			// { date: 'December 1 – February 26, 2026', format: 'ONLINE' },
		],
		starttime: "9:00",
		tag: "mlBootcamp",
		title: "Deep Learning and Artificial Intelligence Engineering Bootcamp",
	}]



	let renderCourses = () => {
		return courses[0].startdates.map((date, i) => (
			<div key={i} className="whiteCard bottomSpace flex300">
				<span>
					<h2>{date.date}</h2>
					<h3>{courses[0].title}</h3>
					<p>Format: <span className='highlighter'>{date.format.toLowerCase()}</span></p>
					<p>Tuition: {date.format === 'ONLINE' ? courses[0].tuitionOnline : courses[0].tuition}</p>
					<p>{date.format === 'ONLINE' ? courses[0].shortdescOnline : courses[0].shortdesc}</p>
				</span>
				<span>
					<p>{date.format === 'ONLINE' ? courses[0].fulldescOnline : courses[0].fulldesc1}</p>
					<p>{courses[0].fulldesc2}</p>
					<Link
						to="/register"
						state={{
							course: courses[0].title,
							date: date.date,
							cost: courses[0].signupprice,
							images: [courses[0].image],
							cryptoID: '1f4b74a9-e09a-4720-aafc-a7c48b261d24'
						}}
					>
						{/*<a href='https://forms.gle/9AnQSr9hJ5frkXmj7' target='blank'>*/}
						<button style={{ marginBottom: "1em" }}>Sign up</button>
						{/*</a>*/}
					</Link>
				</span>
			</div>
		))
	}

	return (
		<div className="flexContent">
			<Helmet
				title="Deep Learning and Artificial Intelligence Engineering Bootcamp"
				meta={[
					{
						name: "description",
						content:
							"Deep Learning and Artificial Intelligence Engineering Bootcamp: Artificial Intelligence development with BERT, GPT, diffusion models, neural radiance field (NeRF), Reinforcement learning Language models and transformers Artificial, convolution, recurrent and generative neural networks Machine learning, tree models, K-means clustering, Programming with Python, data loading and data visualization, Machine Learning, Data Wrangling, Data Visualization",
					},
					{
						name: "keywords",
						content:
							"Deep learning bootcamp course in barcelona, Artificial intelligence  bootcamp course in barcelona, Neural networks  bootcamp course in barcelona, Machine learning  bootcamp course in barcelona, Computer vision, Natural language processing, Deep reinforcement learning, Convolutional neural networks, Recurrent neural networks, Generative models, Reinforcement learning, Robotics, Autonomous systems, Big data, Optimization techniques, Data pre-processing, Supervised learning, Unsupervised learning, Semi-supervised learning, Transfer learning",
					},
					{
						name: "viewport",
						content: "width=device-width, initial-scale=1",
					},
					{
						property: "og:title",
						content:
							"Deep Learning and Artificial Intelligence Engineering Bootcamp",
					},
					{
						property: "og:description",
						content:
							"Deep Learning and Artificial Intelligence Engineering Bootcamp: Artificial Intelligence development with BERT, GPT, diffusion models, neural radiance field (NeRF), Reinforcement learning Language models and transformers Artificial, convolution, recurrent and generative neural networks Machine learning, tree models, K-means clustering, Programming with Python, data loading and data visualization, Machine Learning, Data Wrangling, Data Visualization",
					},
					{
						property: "og:image",
						content:
							"https://barcelonacodeschool.com/files/pics/deep_learning_bootcamp.jpg",
					},
					{
						property: "og:url",
						content:
							"https://barcelonacodeschool.com/machine-learning-and-ai-engineering-bootcamp/",
					},
					{ name: "twitter:card", content: "summary_large_image" },
				]}
				script={[{ src: "https://js.stripe.com/v3/" }]}
			/>


			<img
				className="banners flex1200 offsetImage"
				src={Inperson}
				alt="Deep Learning and Artificial Intelligence Engineering Bootcamp in Barcelona Code School"
			/>

			<h1 className='transparentContainer flex225'>
				<span className='highlighter'>Deep Learning and Artificial Intelligence Engineering Bootcamp</span> in Barcelona Code School
			</h1>

			<h2 className='transparentContainer flex225'>
				1:1 Online Mentored Bootcamp
			</h2>

			<div className='transparentContainer flex225'>
				<h3>What is Machine Learning Engineer?</h3>
				<div className='whiteCard'>
					<p>A Machine Learning Engineer designs, builds, productionizes, optimizes, operates, and maintains ML systems.</p>
					<p>Our course is focused primarly on <span className='highlighter'>using ML for building Artificial Intelligence systems</span>.</p>
				</div>
			</div>





			<div className="transparentContainer">
				<h4>Share on social media</h4>
				<div className="socialsharebuttons">
					<div className="socialshare" style={{ 'marginLeft': 0 }}>
						<a
							className="twitter-share-button "
							href="https://twitter.com/intent/tweet?text='Deep Learning and Artificial Intelligence Engineering Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/machine-learning-and-ai-engineering-bootcamp/"
							data-size="large"
						>
							<button>tweet</button>
						</a>
					</div>

					<div
						className="fb-share-button socialshare"
						data-href="https://developers.facebook.com/docs/plugins/"
						data-layout="button"
						data-size="small"
					>
						<a
							target="_blank"
							href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/machine-learning-and-ai-engineering-bootcamp/"
							className="fb-xfbml-parse-ignore"
						>
							<button>facebook</button>
						</a>
					</div>
				</div>
			</div>



			<article className='transparentContainer flex900'>
				<h2>Course format</h2>

				<div style={{ 'marginTop': '1em' }} className='whiteCard flex300'>
					<h3>Online, Mentored, 1:1, Part-time</h3>
					<ul className='neonList'>
						<li><span className='highlighter'>13 weeks</span> duration</li>
						<li>Can be done <span className='highlighter'>part-time</span></li>
						<li>Get text and video materials upfront to have flexibility when to study during the day</li>
						<li><span className='highlighter'>Mentored and guided</span></li>
						<li><span className='highlighter'>Support platform</span> to get help</li>
						<li>Regular <span className='highlighter'>1:1 sessions with your mentors</span></li>
						<li>The course is taught <span className='highlighter'>in English</span></li>
						<li>Modern curriculum with all the latest development in <span className='highlighter'>machine learning and AI</span></li>
						<li>An industry-recognized graduation <span className='highlighter'>certificate</span></li>
					</ul>
				</div>

			</article>


			<article className="transparentContainer flex450">
				<h2>What you'll learn</h2>
				<div className='whiteCard'>

					<ul className='neonList'>
						<li>Artificial Intelligence development with BERT, GPT, diffusion models, neural radiance field (NeRF)</li>
						<li>Reinforcement learning</li>
						<li>Language models and transformers</li>
						<li>Artificial, convolution, recurrent and generative neural networks</li>
						<li>Machine learning, tree models, K-means clustering</li>
						<li>Advanced Programming with Python</li>
						<li>Data Loading, Data Wrangling, Data Visualization</li>
					</ul>
				</div>
			</article>

			<article className="transparentContainer flex300">
				<h2>Tools</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li>Python</li>
						<li>Jupiter</li>
						<li>Matplotlib</li>
						<li>Pandas</li>
						<li>Tensorflow</li>
						<li>Tensorboard</li>
						<li>Scikit-Learn</li>
					</ul>
				</div>
			</article>




			<article className="transparentContainer flex450" >
				<h2>The outcomes</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li>
							<span>
								Get hired!
							</span>
							<p>After the bootcamp you will be able to start working as a junior engineer. Possible job titles would be:</p>
							<ul>
								<li>Artificial Intelligence Engineer</li>
								<li>Deep Learning Engineer</li>
								<li>Deep Neural Networks Engineer</li>
								<li>Natural Language Processing Engineer</li>
								<li>Data Scientist</li>
								<li>Machine Learning Engineer</li>
								<li>Data Engineer</li>
								<li>Computer Vision Engineer</li>
								<li>Voice and Audio Processing Engineer </li>
							</ul>
						</li>
						<li>
							Enter the most cutting edge and fast-growing IT field with a portfolio of completed projects done during the bootcamp showcasing your practical skills!
						</li>
					</ul>
				</div>
			</article>

			{/* curriculum breakdown*/}
			<article id='curriculumBreakdown' className='transparentContainer flex900'>

				<h2>Curriculum breakdown</h2>

				<div className='whiteCard bottomSpacer'>
					<h3>Module 1: Python</h3>
					<p>In the first module we will reinforce the basics of Python and introduce you to 2 fundamental data management and data visualization tools: <span className='highlighter'>Pandas</span> and <span className='highlighter'>Matplotlib</span>.</p>
					<p>By the end of this module you will be able to <span className='highlighter'>load data, explore it, clean it and visualize</span> it in interesting ways.</p>
				</div>


				{/*		<div className='whiteCard bottomSpacer'>
		<h3>Module 2: Math & Probability</h3>
		<p>This module we will introduce the advanced math and probability that you need to know to start your journey in Machine Learning. A strong understanding of math and probability is <span className='highlighter'>key to successfully progress in AI & ML</span>.</p>
		</div>*/}



				<div className='whiteCard bottomSpacer'>
					<h3>Module 2: Classical ML, Tree-Based Models, K-Means Clustering and more...</h3>
					<p>You will learn some of the <span className='highlighter'>ML models</span> that every Deep Learning Engineer and Data Scientist should know with a mix of both theory and practice.</p>
				</div>





				<div className='whiteCard bottomSpacer'>
					<h3>Module 3: Intro to Neural Networks / TensorFlow</h3>
					<p>During this module we will introduce you to <span className='highlighter'>Neural Networks</span>, <span className='highlighter'>Deep Learning</span> and <span className='highlighter'>TensorFlow</span>.</p>
					<p>You will learn almighty <span className='highlighter'>Gradient Descent</span>, <span className='highlighter'>Back Propagation</span>, optimization methods for Deep Learning. You will gain a solid understanding of TensorFlow and TensorBoard.</p>
					<p>Project suggestions: we will start working on a <span className='highlighter'>MNIST</span> handwritten digit database.</p>
				</div>



				<div className='whiteCard bottomSpacer'>
					<h3>Module 4: Recurrent Neural Networks & Convolutional NN</h3>
					<p>This module you will be introduced to <span className='highlighter'>Convolutional and Recurrent Neural Networks</span>, and various visual (like object detection, recognition, segmentation) and text (sentiment analysis, text classification) analysis techniques.</p>
					<p>Project suggestions: NLP (spam recognition, sentiment analysis, text classification), CV (object detection, background detection, object counting and tracking).</p>
				</div>







				<div className='whiteCard bottomSpacer'>
					<h3>Module 5: Generative Neural Networks (GANS and VAE)</h3>
					<p>In this module we will start digging into more advanced concepts of ML, GANs (<span className='highlighter'>General Adversarial Neural Networks</span>) and VAEs (<span className='highlighter'>Variational Autoencoders</span>).</p>
					<p>It is of the utmost importance that you become capable of implementing your own networks. In this module we will let you follow through a few selected papers and <span className='highlighter'>implement such networks from scratch</span>.</p>
					<p>Project suggestions: image colorization, text-to-image synthesizer, noise reduction, new human pose generation.</p>
				</div>






				<div className='whiteCard bottomSpacer'>
					<h3>Module 6: Language Models & Transformers</h3>
					<p>In this module we will learn about the most important advancement of the last 4 years in Deep Learning: <span className='highlighter'>Transformers</span>. We will also understand why they perform the way they do, and what are their limitations. We will work with <span className='highlighter'>Transfer Learning</span> and <span className='highlighter'>Model Tuning</span> to speedup model development.</p>
					<p>We will implement the famous paper “Attention is all you need”, and a few other selected papers.</p>
					<p>Project suggestions: chat bot, object detection with vision transformers.</p>
				</div>








				<div className='whiteCard bottomSpacer'>
					<h3>Module 7: Final Project / Intro to modern AI (BERT, GPT, Diffusion Models, NeRFs, Data Annotation, deployment)
					</h3>
					<p>This is your time to shine! <span className='highlighter'>Develop your own unique project</span>, or follow one of the pre-prepared tasks.</p>
					{/*<li>Teach a class about one of the advanced topics in ML</li>*/}
					<p>Projects suggestions: object video tracking, text to audio system, depth/volume extraction from image, translation chatbot.</p>
				</div>



			</article>

			<article className='transparentContainer flex450'>
				<h2>Your mentor</h2>
				<div className='whiteCard'>
					<h2>Dr. Minerva Singh</h2>
					<img className='offsetImage' style={{
						width: '40%',
						float: 'left',
						margin: '0 1em 1em 0'
					}}
						src={Minerva} />
					<p>Minerva holds a PhD from the University of Cambridge, MPhil from the School of Geography and Environment and an MSc from the Department of Engineering at Oxford University. With over a decade of experience in academic research, Minerva has contributed to renowned peer-reviewed journals like PLOS One, showcasing expertise in data science, deep learning, and earth observation (EO) for both non-governmental and industry stakeholders.</p>

					<p>With strong track record in machine learning, data visualization, spatial data analysis, deep learning, and natural language processing using R and Python combined with many years of mentoring experience Minerva is the best instructor for this bootcamp.</p>
					<p>Specializing in a variety of topics ranging from deep learning (Tensorflow, Keras) to machine learning to spatial data analysis (including EO data processing), data visualizations, natural language processing, financial analysis among others Minerva has a valuable real world experience to share with the students.</p>
					<p>Minerva also has acted as a peer reviewer on highly regarded academic journals such as Remote Sensing and given guest lectures on prestigious forums such as Open Data Science Conference (ODSC).</p>
				</div>
			</article>

			{/*prerequisites*/}
			<article className='transparentContainer flex300'>
				<h2>Prerequisites</h2>
				<div className='whiteCard'>
					<p><strong>Python:</strong> We will provide you with a Python videocourse which starts from the basics and takes you to the advanced level. It contains about 7 hours of video lessons and around 100 of exersies with tests for you to practice in case you need to level up your Python.</p>
				</div>
			</article>

			<article className='transparentContainer flex450'>
				<h2>Tuition</h2>
				<div className='whiteCard'>
					<h2>3950€</h2>
					<p>
						900€ paid upon registration, the remaining is due before course begins.
					</p>


				</div>
			</article>


			<article className='transparentContainer flex300'>
				<h2>Financing</h2>
				<div className='whiteCard'>
					<p>We can assist in getting a student loan which usually have lower rates than consumer loans.</p>
					<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
					<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
					<Instalments />
				</div>
			</article>

			<article className='transparentContainer flex900'>
				<h2>How to join the bootcamp</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li>Choose the starting date and register online with 900€ payment which is part of the tuition <span className='nextStepArrow'>↴</span></li>
						<li>Fill out the student's form <span className='nextStepArrow'>↴</span></li>
						<li>Receive the prep materials <span className='nextStepArrow'>↴</span></li>
						<li>Join our Slack workspace <span className='nextStepArrow'>↴</span></li>
						<li>Start preparing <span className='nextStepArrow'>↴</span></li>
						<li>Make the remaining payment due 5 days before the starting date <span className='nextStepArrow'>↴</span></li>
						<li>On day 1 receive the workflow instructions and main materials to start working on the course, submitting your work and booking your 1:1 sessions.</li>
					</ul>
				</div>
			</article>

			<article className="transparentContainer flex1200">
				<h2>Access

					<span
						id="cohorts"
						style={{
							position: "relative",
							top: "-50px",
							visibility: "hidden",
						}}
					>
						.
					</span>
				</h2>

				{/*<Covid course={"jsinperson"} />*/}
				<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>
			</article>










			<article className='transparentContainer flex225'>
				<h2>International students</h2>
				<div className='whiteCard'>
					<p>You are welcome!</p>
					<p>A lot of our students are taking the course from abroad. Since this bootcamp is completely remote, you can join it from anywhere in the world!</p>
				</div>
			</article>



			<article className="transparentContainer flex450">
				<h2>Why Barcelona Code School?</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li>
							<span className="highlighter">
								Cutting edge curriculum
							</span>	tailored to job market and companies’ needs
						</li>
						<li>
							Best	<span className="highlighter">
								students/mentors ratio
							</span>
						</li>
						<li>
							Instructors, who <span className="highlighter">know how to teach</span>
						</li>
						<li>Fast track from beginner to job-ready coder</li>
						<li>
							<span className="highlighter">Job support</span> for the
							graduates
						</li>
						<li>Life-time career support</li>
						<li>
							Premium location – cozy campus in the downtown of Barcelona away from the touristic crowds
						</li>
					</ul>
				</div>
			</article>



			<article className="transparentContainer flex450">
				<h2>Why Machine Learning and AI?</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li>
							According to the latest <a href='https://www.hackerrank.com/research/developer-skills/2023' target="blank">HackerRank survey</a> the most in-demand skills in the job market in 2022 were (ordered by volume):
							<ul>
								<li>1. <span className='highlighter'>Problem Solving</span></li>
								<li>2. <span className='highlighter'>Machine Learning</span></li>
								<li>3. REST API</li>
								<li>4. React</li>
								<li>5. HTML/CSS/IS</li>
								<li>6. <span className='highlighter'>Data Wrangling</span></li>
								<li>7. Node.is</li>
								<li>8. <span className='highlighter'>Data Visualization</span></li>
							</ul>
							<p>As you can see Machine Learning and Data Engineering fields are among the top in-demand in the market.</p>
						</li>
						<li>Data science-related skills are the biggest gainers in the same survey, show that there is more and more demand for Data Engineers.</li>
						<li>
							Recent exponential progress in the AI engineering field means spike in demand for the Data Engineers as now technology allows more business start using AI.</li>
					</ul>
				</div>
			</article>


			<div className='flex900'><img className="banners offsetImage" src={OurStudents00} /></div>


			<article className='transparentContainer flex450'>
				<h2>FAQ</h2>
				<div className='whiteCard'>
					<details>
						<summary><strong>What computer would I need?</strong></summary>
						<p>Any operating system will do, Mac, Windows or Linux. If you are thinking about buying a new one then the best choice would be an Apple laptop: Macbook, Macbook Air or Macbook Pro. On Windows laptop everything is doable as well, simply some things work slower on Windows but it's not going to prevent you from succeeding!</p>
					</details>

					<details>
						<summary><strong>What is the language of this course?</strong></summary>
						<p>English.</p>
					</details>

					<details>
						<summary><strong>Do I have to speak Spanish?</strong></summary>
						<p>For the course you do not need Spanish. Everybody at our school speak English. To feel comfortable in Barcelona Spanish would help but nowadays in most of the places local people do speak English.</p>
					</details>

					<details>
						<summary><strong>What qualification I will get from this course?</strong></summary>
						<p>After succesfull completion you will become a junior Machine Learning and Artificial Intelligence Engineer and will be able to apply for any relevant entry engineering positions.</p>
					</details>



					<details>
						<summary><strong>How good would a typical graduate be after completing the course?</strong></summary>
						<p>You will be able to work in Machine Learning, Deep Learning, AI development as a junior engineer. </p>
					</details>

					<details>
						<summary><strong>Is there a pre-course?</strong></summary>
						<p>Yes! We will send you some training materias to complete before the bootcamp.</p>
					</details>

					<details>
						<summary><strong>How does your price compare to other schools?</strong></summary>
						<p>It’s safe to say that our price is within lower competitive range. We strive to provide the personalized approach thanks to smaller group size and attract highly qualified instructors motivated to share their expertise with students. You will love it, we guarantee!</p>
					</details>
				</div>
			</article>

			<div className='flex450'>
				<Contacts
					title={"Inquiry about the course"}
					subject={"Inquiry about ML/AI online bootcamp"}
				/>
			</div>

			<article className='transparentContainer flex900'>
				<h2>Some of the companies where our graduates work or worked</h2>
				<div>
					<PartnersLogos />
				</div>
			</article>

		</div>

	)
}

export default MLBootcamp
