import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ReactDom from 'react-dom';
import {stack} from '../filestorage/vars.js'


var title = "Looking for a developer?"
// const Calendar = () => (

class JobOffer extends React.Component {




	constructor() {
		super()
		this.state = {
			hey: 'Me',
			color: 'black',
			title: "Looking for a developer or designer?",
			buttonDisabled: false
		}
	}


	handleSubmit(event) {
		this.setState({buttonDisabled:true})
		var that = this
		event.preventDefault()
		const nameInput = event.target.elements.name
		const companyInput = event.target.elements.company
		const locationInput = event.target.elements.location
		const remoteInput = event.target.elements.remote
		const jobInput = event.target.elements.job
		const emailInput = event.target.elements.email
		const messageInput = event.target.elements.message
		const humanCheck = event.target.elements.noRobotsAllowed
		// if (humanCheck != 9) { return alert("Anti robot check not passed, please try again Mr. Robot")}
		if (humanCheck.value != 9) {
			that.setState({ color: 'red' })
			return alert("Anti robot check not passed, please try again")
		}

		that.setState({ color: 'black' })
		var data = { name: nameInput.value, company: companyInput.value, email: emailInput.value, job: jobInput.value, location: locationInput.value, remote: remoteInput.value }
		// console.log("--data--", name: event.target.elements.name.value, event.target.elements.email.value, event.target.elements.message.value)
		axios.post('/jobOffer', data)
		.then((response) => {
			nameInput.value = ""
			emailInput.value = ""
			locationInput.value = ""
			remoteInput.value = ""
			companyInput.value = ""
			jobInput.value = ""
			humanCheck.value = ""
			alert("Your message has been sent, thanks!")
			that.setState({buttonDisabled:false})
		})
		.catch(function (error) {
			// console.log(error);
			that.setState({buttonDisabled:false})
		})
		// console.log("--SeNd!--")
	}

	render() {
		const style = {
			color: this.state.color
		}


		return (
			<div
			className='transparentContainer'
			>

			<h1 className="pageTitle">{this.state.title}</h1>

			<div className="whiteCard">
			<h2>Send us your job offer to get matched with a qualified gradute.</h2>
			<p>Our web/mobile development Bootcamp's graduates are job-ready for the junior developer positions with the following stack: {stack}.</p> 
			<p>UX / UI Design Bootcamp's graduates are qualified for the junior desiner positions in user experience and/or user interface design.</p>
			<p>The Game Development Bootcamp's graduates are qualified to start working as junior developers in the game industry with Unity / C#.</p>
			<form onSubmit={this.handleSubmit.bind(this)}>
			
			<input required={true} style={{
				marginBottom: '2em',
				marginTop: '0',
				border: '1px solid grey',
				display: 'inline'
			}}
			type="text" placeholder="What is your name?" name="name" />

			<input required={true} style={{
				marginBottom: '2em',
				marginTop: '0',
				border: '1px solid grey',
				display: 'inline'
			}}
			type="text" placeholder="Name of your company?" name="company" />

			<textarea required={true} style={{
				marginBottom: '2em',
				marginTop: '0',
				border: '1px solid grey'
			}}
			placeholder="Job description"
			name="job"
			/>

			<input required={true} style={{
				marginBottom: '2em',
				marginTop: '0',
				border: '1px solid grey'
			}}
			type="text" placeholder="Location?" name="location" />

			<p>Is the job remote or on-site? <select
			style={{
				border: '1px solid grey',
				marginTop: '0',
				marginBottom: '2em',
			}}
			name="remote">
			<option value="remote">Remote</option>
			<option value="on-site">On-site</option>
			</select></p>
			



			<input required={true}
			style={{
				border: '1px solid grey',
				marginBottom: '2em',
				marginTop: '0',
			}}
			type="email" placeholder="Your contact email?" name="email" />
			<p style={style}>If you are not a robot, how much would be 3 plus 6? <input required={true}
			style={{
				width: '2em',
				border: '1px solid grey',
				marginTop: '0',
				marginBottom: '2em',
			}}
			type="text" placeholder="" name="noRobotsAllowed" /></p>
			
			<button type="submit" label="Send" >Send!</button>
			</form>
			</div>

			</div>
			)
	}
}
// )

export default JobOffer