import React from 'react'

export default function internationalStudents() {
	return (
 		<article className='transparentContainer flex225'>
 		<h2>International students</h2>
 		<div className='whiteCard'>
 		<p>You are welcome!</p>
 		<p>A lot of our students are coming from abroad. The course is in English so there is no language barrier. And if you will need help with visa we can send you an acceptance letter to attach to your visa application!</p>
 		</div>
 		</article>
	)
}