import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
// import axios from 'axios'
// import ReqCur from '../components/reqCur'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import PartnersLogos from '../components/partnerLogos.js'

import UXlogos from "../components/uxLogos.js"

// import InternationalStudents from '../components/internationalStudents.js'
import Instalments from '../components/instalments.js'
import UXProjectsExamples from '../components/UXProjectsExamples.js'

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../components/checkout';

import Contacts from '../components/contactform';
// import Covid from '../components/covid';

import UXBanner from '../pics/ux_online_course.jpg'

// import Marina from '../pics/team/marina_tudela.jpg'
// import Margarita from '../pics/team/margarita_barcelona_code_school.jpg'

// import Mateo from '../pics/students/mateo.png'
// import Chris from '../pics/students/chris.png'
// import Brett from '../pics/students/brett.png'


// import coursicab from '../pics/partners/coursicab.png'



let UXBootcamp = () => {
	let [weeks, setWeeks] = useState({
		week1: 'none',
		week2: 'none',
		week3: 'none',
		week4: 'none',
		week5: 'none',
		week6: 'none',
		week7: 'none',
		week8: 'none',
		week9: 'none',
	})


	let showFull = (event) => {
		weeks[event.target.title] === 'block' ? setWeeks({ ...weeks, [event.target.title]: 'none' }) : setWeeks({ ...weeks, [event.target.title]: 'block' })
	}

	// let [courses, setCourses] = useState([])

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/UXbootcamp')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])
	let course =
	{
		durationdays: 61,
		durationweeks: 9,
		fulldesc1: "Self-paced, online, mentored, in English",
		fulldesc2: "Registration and tuition payment 2900€.",
		fullprice: 2900,
		image: UXBanner,
		priceoptions: ['2900'],
		shortdesc: "Learn UX Design process on a real project",
		signupprice: 2900,
		tag: "UXbootcamp",
		title: "UX / UI Design Online Mentored Bootcamp"
	}

	const [cryptoCost, setCryptoCost] = useState(2900)

	const [selected, setSelected] = useState('');
	const today = new Date()
	let footer = <p>You picked {selected && selected.toLocaleDateString()}.</p>;
	const dayOfWeekMatcher = { dayOfWeek: [0, 2, 3, 4, 5, 6] }
	const disabledDays = { from: new Date(2022, 12, 1), to: new Date(today.setDate(today.getDate() + 14)) }

	return <div className="flexContent">
		<Helmet
			title="UX / UI Design Online Mentored Bootcamp in Barcelona Code School"
			meta={[
				{ name: 'description', content: 'Competence-based part-time UX / UI Design Online Mentored Bootcamp in Barcelona Code School. ' },
				{ name: 'keywords', content: 'ux design bootcamp, ux bootcamp, design bootcamp in barcelona, ux ui design bootcamp in barcelona, ui bootcamp, ux course in barcelona, ux workshop in barcelona, learn ux design, learn Sketch, learn Adobe XD, learn InVision, learn InVision Studio, learn Flinto' },
				{ property: "og:title", content: "UX / UI Design Online Mentored Bootcamp in Barcelona Code School" },
				{ property: "og:description", content: "UX / UI Design Online Mentored Bootcamp in Barcelona Code School. Part-time remote and self-paced. Learn UX Design mentored by our instructors." },
				{ property: "og:image", content: "https://barcelonacodeschool.com/files/pics/ux_online_course.jpg" },
				{ property: "og:url", content: "https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/" },
				{ name: "twitter:card", content: "summary_large_image" },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
			]}
			script={[
				{ "src": "https://js.stripe.com/v3/" }
			]}
		/>


		<img
			className='banners flex1200 offsetImage'
			src={UXBanner}
			alt='UX / UI Design Online Mentored Bootcamp in Barcelona Code School' />


		<h1 className='transparentContainer flex450'><span className='highlighter'>UX / UI Design Online 1:1 Mentored Bootcamp</span> in Barcelona Code School</h1>

		<h2 className='transparentContainer flex450'>Learn User Experience and User Interface Design by completing one real life project guided by our instructor!</h2>


		<div className='transparentContainer flex300'>
			<h2>Tools</h2>
			<div className='whiteCard'>
				<UXlogos />
			</div>
		</div>

		<div className='transparentContainer flex600'>
			<h2>What is this course about</h2>
			<article className='whiteCard'>
				<ul className='neonList'>
					<li>Learn the <span className='highlighter'>whole UX Design process</span> from the idea to the prototype. We will guide you through all the steps of the UX and UI Design process applied on the project. Not only you will understand the theory and science behind UX Design but also will have an experience of implementing it with supervision from our instructors.</li>
					<li>Fully <span className='highlighter'>online 1:1 mentored hands-on</span> training</li>
					<li>9 weeks of guided learning with weekly video calls with your instructor and chat support in between</li>
					<li>A completed project in the end</li>
					<li>Documenting the whole process</li>
					<li>Using industry standards aplicable in the real world</li>
					<li>Be guided by your instructor through all the steps</li>
					<li>The course is taught in <span className='highlighter'>English</span></li>
					<li>A <strong className='highlighter'>graduate certificate</strong></li>
				</ul>
			</article>
		</div>


		<div className='transparentContainer flex600'>
			<h2>How</h2>
			<article className='whiteCard'>
				<ol className='neonList'>
					<li>Register for the course from this page.</li>
					<li>Get the list of recommended pre-course materials to read before starting.</li>
					<li>Get all the main course materials.</li>
					<li>Access our workspaces to connect with your instructor.</li>
					<li>Set up your first 1:1 video session with your mentor.</li>
					<li>Together with your instructor define the project you are going to work on through the course.</li>
					<li>Study the materials, complete the practical work, share wiith your instructor, get feedback and help.</li>
					<li>Complete the project by following all the steps of a complete UX cycle.</li>
					<li>Graduate and get an industry-recognized certificate!</li>
				</ol>
			</article>
		</div>

		<div className='transparentContainer flex600'>
			<h2>Why</h2>
			<article className='whiteCard'>
				<h3>Who will benefit most from this course?</h3>
				<p>This course is no-nonsense practical way of learning by doing. It covers all the steps of UX Design process on a real project which you will choose together with your mentor.</p>
				<p>In the end you will have a finished prototype with complete case study. While it can be a good starting point for students interested in launching their UX Designers career we see it especially beneficial for students willing or needing to learn UX as a secondary skill for their current profession.</p>
				<p>For example:</p>
				<ul className='neonList'>
					<li><span className='highlighter'>Developers</span> willing to understand how to do research and plannign of their apps before they will be coded.</li>
					<li><span className='highlighter'>Project Managers</span> and <span className='highlighter'>Product Owners</span> willing to understand this crucial stage of making a product.</li>
					<li><span className='highlighter'>UI Designers</span> and <span className='highlighter'>Web Designers</span> after taking this course will be able to make more informed decisions at their daily work.</li>
					<li>It will help <span className='highlighter'>Founders</span> to do crucial research and save money before starting the production</li>
				</ul>
			</article>
		</div>



		<div className="transparentContainer">
			<h4>Share on social media</h4>
			<div className="socialsharebuttons">
				<div className="socialshare" style={{ 'marginLeft': 0 }}>
					<a
						className="twitter-share-button "
						href="https://twitter.com/intent/tweet?text='UX / UI Design Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
						data-size="large"
					>
						<button>tweet</button>
					</a>
				</div>

				<div
					className="fb-share-button socialshare"
					data-href="https://developers.facebook.com/docs/plugins/"
					data-layout="button"
					data-size="small"
				>
					<a
						target="_blank"
						href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
						className="fb-xfbml-parse-ignore"
					>
						<button>facebook</button>
					</a>
				</div>
			</div>
		</div>


		{/*	<article className='transparentContainer flex450'>
	<h2>Get the syllabus</h2>
	<div className='whiteCard'>
	<p>Subscribe to our mailing list and get the syllabus now.</p>
	<ReqCur course="UX / UI Design Bootcamp" link="https://barcelonacodeschool.com/files/UX_UI_Design_Bootcamp_Syllabus_Full_Time.pdf" courselink="https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"/>
	</div>
	</article>*/}

		<article className='transparentContainer flex450'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
				<h2>2900€</h2>
				<p>One-time payment to register for the course</p>
				<Instalments />
			</div>
		</article>

		<article className="transparentContainer flex1200">
			<h2 className="">
				Access : start any Monday
				<span
					id="dates"
					style={{
						position: "relative",
						top: "-50",
						visibility: "hidden",
					}}
				>
					.
				</span>
			</h2>
			{/*<h4>Starting dates every week</h4>*/}
			{/*<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>*/}


			<div className='flexContent whiteCard flex300'>
				<div className="bottomSpace ">
					<span>
						<h2>{selected ? `You selected ${selected.toLocaleString("default", {
							month: "long",
						})} ${selected.getDate()}, ${selected.getFullYear()}` : 'Please choose your starting date'}</h2>
						<DayPicker
							mode="single"
							selected={selected}
							onSelect={setSelected}
							// footer={footer}
							weekStartsOn={1}
							// disabled={dayOfWeekMatcher}
							disabled={[disabledDays, dayOfWeekMatcher]}
							fromYear={today.getFullYear()}
							defaultMonth={new Date(today.setDate(today.getDate() + 14))}
						/>
					</span>
				</div>

				<div className="bottomSpace flex300">
					<h3>UX / UI Design 1:1 Online Mentored Bootcamp</h3>
					<p>Weekly 1:1 sessions, support platform, chat channel</p>
					<span>
						<p>Online, mentored, in English</p>
						{/*<p>{courses[0].fulldesc2}</p>*/}
						<p>9 weeks</p>

						{selected ? <Link
							to="/register"
							state={{
								course: 'UX / UI Design Online Mentored Bootcamp',
								date: selected && `${selected.toLocaleString("default", {
									month: "long",
								})} ${selected.getDate()}, ${selected.getFullYear()}`,
								cost: 2900,
								cryptoID: '4cf27db8-2669-4cf8-8fd7-ee9c1990d3d8',
								images: [course.image],
							}}
						>

							<button style={{ marginBottom: "1em" }}>Sign up</button> </Link> : <p className='highlighter'>Please choose a starting date fiirst</p>}

					</span>
				</div>
			</div>

		</article>




		<div className='transparentContainer flexContent flex900'>
			<h2>Students cases</h2>
			<article className='whiteCard flex300'>
				<h3>Akira, a developer</h3>
				<p>Akira started working as a developer in a small tech company. She is responsible for implementing new features to the existing projects and also for building projects from scratch.</p>
				<p>The team is small and they do not have a dedicated designers so Akira has to make some design choices as well. Her manager is also trying to participate and they constantly argue about design. Problem is that non of them have a professional knowledge about it and they follow their instinct and feeling.</p>
				<p>By taking this course Akira, as well as her manager, both can get enough knowledge to make informative decisions about UX and UI design without switching their jobs to full-time designers.</p>
			</article>
			<article className='whiteCard flex300'>
				<h3>Scott, a founder</h3>
				<p>After working as data analyst in a financial company Scott came up with an idea for the intra-company app which can facilitate the process of project management between developers and analytics.</p>
				<p>He already has a background in programming and knows how to build this app technically. He also has real users and intuitively udnerstand their needs but he is curious how to conduct the UX research properly to gather all the information he needs and structure it befgore starting to code the app.</p>
				<p>By doing this course he can learn about the methods and techniques of oding research and use this information to properly plan his app so it will not flop.</p>
			</article>
			<article className='whiteCard flex300'>
				<h3>Laura, a project manager</h3>
				<p>Laura works in an agency managing designers and developers for the client's projects. She really loves her job and enjoys working with her team, While process of develoment is very technical and misterious to her she enjoys talking to the UX and UI Designers and she wants to understand better the process to provide more meaningful input into it and translate the needs of the clients better to her team.</p>
				<p>By taking this course she will have a better understanding of all the stages of the UX, what they mean and what they do so she can participate more actively in the design team sprints and become a more valuable team member.</p>
			</article>
		</div>


		<article className="transparentContainer flexContent flex1200">
			<h2>What our students say</h2>
			<div className='whiteCard flex225'>
				<p><strong>"Great intensive course!</strong> Good balance between theory lessons and projects. The small group is perfect to get personal assistance and resolving any doubt. The teachers are very good at adjusting their lessons to students' backgrounds and skills. Also, there are enough projects and content to put a portfolio together at the end of the Bootcamp."</p>
				<p className="author">— Mateo Pardo</p>
			</div>
			<div className='whiteCard flex225'>
				<p>"Studying process was very intense and full of knowledge. I think it <strong>exceeded all my expectations</strong>. Our professional teachers touched every possible topic during 9 weeks of a boot camp. They introduced us to the world of UX / UI, different platforms, tools and motivation videos. Our days were not like routine learning in a boring school, on the contrary, it was fun, easy and educational."</p>
				<p className="author">— Polina</p>
			</div>
			<div className='whiteCard flex225'>
				<p>"The course was a lot of fun to do! Absolutely worth it if you are looking to experience and learn UI/UX design. Instead of spending too much time on theory we jumped right on getting practical. Marina Tudela is friendly and explains everything very clear. She teaches you how to really <strong>get inside the head</strong> of the user/customer. Muchissimas gracias Marina!"</p>
				<p className="author">— R da Silva</p>
			</div>
		</article>

		<UXProjectsExamples />






		<article className='flex450'>
			<div className="video-container offsetImage" style={{ marginBottom: '1em' }}>
				<iframe width="912" height="538" src="https://www.youtube.com/embed/ql8aKWYfDOQ" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen modestbranding='1'></iframe>
			</div>
		</article>


		<article className='transparentContainer flex300'>
			<h2>Why UX</h2>
			<div className='whiteCard'>
				<p>In today’s digital world people’s experiences have become a crucial aspect for every product, company or
					organization. The transversality of the UX field affects all areas of business as well as the technology and its
					development.</p>
				<p>This course will help you know your users better, design products that are truly functional and that
					people love, learn the proper design process and provide designs for real life problems.</p>
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2>But I don’t know anything about UX...</h2>
			<div className='whiteCard'>
				<p>Our course is prepared for people with no previous experience in UX Design. Previous experience with related fields such as project or product management, visual or graphic design, development and QA will help you to better understand the industry, but it’s also ok if you want to start from scratch.</p>
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2>What if I already know something about design?</h2>
			<div className='whiteCard'>
				<p>That’s great! Traditional Design skills (Graphic, Editorial, Digital…) are extremely useful for UX Designers.
					That will help you establish yourself in a higher seniority level once you finish the course.</p>
			</div>
		</article>





		<article className='transparentContainer flex300'>
			<h2>Financing</h2>
			<div className='whiteCard'>
				<p>We can assist in getting a student loan which usually have lower rates than consumer loans.</p>
				<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
				<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
				<Instalments />
			</div>
		</article>







		<div className='transparentContainer flex450'>
			<article className='whiteCard'>
				<h2>FAQ</h2>
				<div>
					<details> <summary><strong>What computer would I need?</strong></summary><p>It can be Mac or Windows.</p>

					</details>
					<details> <summary><strong>What is the language of this course?</strong></summary><p>English.</p>

					</details>

					<details> <summary><strong>Do I have to speak Spanish?</strong></summary><p>For the course you do not need Spanish. Everybody at our school speak English.</p>

					</details>

					<details> <summary><strong>Who is this course for?</strong></summary><p>Beginners. If you don't know what UX design is – this course is for you. If you know what Lean UX is – even better. If you can explain difference between a wireframe and a prototype you will be doing just fine.</p>

					</details>

					<details> <summary><strong>Is there a pre-course?</strong></summary><p>Yes. We send you materials to study to come prepared!</p>

					</details>

					<details> <summary><strong>WHat is the difference between this course and online courses I can buy from Udemy or Coursera??</strong></summary><p>Our course is mentored, you work 1:1 with an instructor who will be available for help via chat channel and weekly video calls. It's a huge difference as you will never be left alone with your problems or questions.</p>
					</details>

				</div>
			</article>
		</div>


		<div className='flex450'>
			<Contacts
				title={"Inquiry about the course"}
				subject={"Inquiry about UX / UI Design Online Mentored Bootcamp"}
			/>
		</div>





		<article className='transparentContainer flex900'>
			<h2>Some of the companies where our graduates work or worked</h2>
			<div>
				<PartnersLogos />
			</div>
		</article>

	</div>
}


export default UXBootcamp


/*
		<article className="transparentContainer flex600">
			<h2 id="cohorts">Access<span id="dates" style={{ position: 'relative', top: '-50', visibility: 'hidden' }}>.</span></h2>
			<div className='flexContent'>
				<div className="whiteCard bottomSpace flex300" >
					<span>
						<h3>{course.title}</h3>
						<p>{course.shortdesc}</p>
					</span>
					<span>
						<p>{course.fulldesc1}</p>
						<p>{course.fulldesc2}</p>
						
						<Link className='buttonLink' to="/register" state={{ course: course.title, date: (new Date()).toDateString().slice(4), cost: course.signupprice, images: [course.image],cryptoID: '4cf27db8-2669-4cf8-8fd7-ee9c1990d3d8' }}>
							<button style={{ 'marginBottom': '1em' }}>Sign up and start the course</button>
						</Link>
					</span>
				</div>
			</div>
		</article>
*/