import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

// import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import CodingLogos from "../../components/codingLogos.js"
import PartnersLogos from "../../components/partnerLogos.js"

import InternationalStudents from '../../components/internationalStudents.js'
import Instalments from '../../components/instalments.js'
import JSProjectsExamples from "../../components/JSProjectsExamples.js";

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../../components/checkout';

import Contacts from "../../components/contactform"
import Covid from "../../components/covid"

import ReqCur from "../../components/reqCur"

import vr from '../../pics/web_development_online_bootcamp_barcelona_code_school.jpg'
// import vr from "../../pics/javascript_fullstack_online_self_paced_bootcamp.png"

import Mike from "../../pics/students/mike.jpg"
import Diane from "../../pics/students/diane_sq.jpg"
import Carlos from "../../pics/students/carlos_planchart.jpg"
import Yen from "../../pics/students/yen.jpg"

import CR from "../../pics/BestOnlineBadge2020.png"
import switchup from "../../pics/switchup2020.png"

import Badges from '../../components/badges'
import { el } from "date-fns/locale";

// import events from '../../assets/events.js'

const JSBootcampOnline = () => {
	let [duration, setDuration] = useState(null)
	const [cryptoCost, setCryptoCost] = useState(null)

	const [selected, setSelected] = useState('');
	const today = new Date()
	let footer = <p>You picked {selected && selected.toLocaleDateString()}.</p>;
	const dayOfWeekMatcher = { dayOfWeek: [0, 2, 3, 4, 5, 6] }
	const disabledDays = { from: new Date(2022, 12, 1), to: new Date(today.setDate(today.getDate() + 14)) }


	let handleChange = (event) => {
		setDuration(event.target.value)
		if (event.target.value === '2100') {
			setCryptoCost('460f66dc-99fd-4d7d-b32b-b7c0c45f58d5') // 2100€
		}else if (event.target.value === '2500') {
			setCryptoCost('73e04fd8-430d-49cd-8873-2ade520582d0') // 2500€
		}else if (event.target.value === '2900') {
			setCryptoCost('b619e67a-9656-44f0-92c0-63f512e70a5e') // 2900€
		}
	}


	return (
		<div className="flexContent">
			<Helmet
				title="Web / Mobile Development Online Mentored Bootcamp in Barcelona Code School - learn React, React Native, Node, Express, MongoDB, JavaScript, HTML, CSS"
				meta={[
					{
						name: "description",
						content:
							"Web / Mobile Development Online Mentored Bootcamp in Barcelona Code School. Self-paced online course to become a developer. Learning JavaScript, TypeScript, React, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
					},
					{
						name: "keywords",
						content:
							"Web / Mobile Development online Mentored bootcamp, react remote bootcamp, javascript remote bootcamp, online javascript bootcamp, online javascript bootcamp in barcelona, online web development bootcamp in barcelona, online react bootcamp",
					},
					{
						name: "viewport",
						content: "width=device-width, initial-scale=1",
					},
					{
						property: "og:title",
						content:
							"Web / Mobile Development Online Mentored Bootcamp in Barcelona Code School - learn React, React Native, Node, Express, MongoDB, TypeScript, JavaScript, HTML, CSS",
					},
					{
						property: "og:description",
						content:
							"Web / Mobile Development Online Mentored Bootcamp in Barcelona Code School. Mentored online course with full-time or part-time schedule. Teaching JavaScript, TypeScript, React, React Native, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
					},
					{
						property: "og:image",
						content:
							"https://barcelonacodeschool.com/files/pics/web_development_online_bootcamp_barcelona_code_school.jpg",
					},
					{
						property: "og:url",
						content:
							"https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/",
					},
					{ name: "twitter:card", content: "summary_large_image" },
				]}
				script={[{ src: "https://js.stripe.com/v3/" }]}
			/>
			<img
				className="banners flex1200 offsetImage"
				src={vr}
				alt="Online Web / Mobile Development Bootcamp in Barcelona Code School"
			/>

			<h1 className='transparentContainer flex225'>
				<span className="highlighter">Web / Mobile Development Online Mentored Bootcamp</span> in Barcelona
				Code School
			</h1>


			<h2 className='transparentContainer flex450'>Become a full-stack developer and build web and mobile apps powered by JavaScript</h2>

			<div className='transparentContainer flex450'>
				<h3>What is a web/mobile developer?</h3>
				<div className='whiteCard'>
					<p>A <strong>web/mobile developer builds, productionizes, optimizes and maintains web and mobile applications</strong>.</p>
					<p>A <strong>web application</strong> is <strong className='highlighter'>a website with functionality and user interactions</strong>, which is not only displaying static content but also allows users to do something. For example, <span className='highlighter'>YouTube</span> (users can upload videos, watch them, comment, etc..), <span className='highlighter'>Reddit</span> (users can post, read, comment, etc...), <span className='highlighter'>Amazon</span> (users can buy products, sellers can sell them, etc...) are all web applications.</p>
					<p>A <strong>mobile app</strong> is any app you can <strong className='highlighter'>install in your mobile phone</strong>. For example, <span className='highlighter'>Weather</span>, <span className='highlighter'>Notes</span>, <span className='highlighter'>Mail</span>, <span className='highlighter'>TikTok</span>, <span className='highlighter'>Telegram</span>, <span className='highlighter'>Netflix</span> and so on...</p>
					<p>Our <strong>course</strong> is focused primarly on <strong>developing web and mobile apps</strong> (for both iOS and Android) with the <strong>JavaScript MERN stack</strong> which includes <strong className='highlighter'>React</strong>, <strong className='highlighter'>TypeScript</strong>, <strong className='highlighter'>Node</strong>, <strong className='highlighter'>Express</strong>, <strong className='highlighter'>mongoDB</strong> and of course <strong className='highlighter'>JavaScript</strong>, <strong className='highlighter'>HTML</strong>, <strong className='highlighter'>CSS</strong> + <strong className='highlighter'>React Native</strong> for mobile developemnt.</p>
				</div>
			</div>

			<div className='transparentContainer flex225'>
				<p className='whiteCard'>Bootcamps since 2017 with continuous updates and improvements</p>
				<Badges activeLinks={false} />
			</div>

			<div className="transparentContainer">
				<h4>Share on social media</h4>
				<div className="socialsharebuttons">
					<div className="socialshare" style={{ 'marginLeft': 0 }}>
						<a
							className="twitter-share-button "
							href="https://twitter.com/intent/tweet?text='Web / Mobile Development Online Mentored Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/"
							data-size="large"
						>
							<button>tweet</button>
						</a>
					</div>

					<div
						className="fb-share-button socialshare"
						data-href="https://developers.facebook.com/docs/plugins/"
						data-layout="button"
						data-size="small"
					>
						<a
							target="_blank"
							href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/"
							className="fb-xfbml-parse-ignore"
						>
							<button>facebook</button>
						</a>
					</div>
				</div>
			</div>

			{/*<Badges activeLinks={false}/>*/}

			<article className='transparentContainer flex600'>
				<h2>Get our awesome syllabus</h2>
				<div className='whiteCard'>
					<p>Subscribe to our mailing list and get the syllabus now.</p>
					<ReqCur
						course="Web / Mobile Development Online Mentored Bootcamp"
						link="https://barcelonacodeschool.com/files/BCS_Web_and_Mobile_Development_Bootcamp_Syllabus.pdf"
						courselink="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-online-bootcamp/"
					/>
				</div>
			</article>


			<article className='transparentContainer flex300'>
				<h2>Course format</h2>
				<ul className=' whiteCard neonList'>
					<li><span className="highlighter">Mentored</span> remote training</li>
					<li><span className='highlighter'>Personalized guidance</span> for each student</li>
					<li>Learn <span className="highlighter">full-stack</span> from home</li>
					<li>Completely <span className="highlighter">flexible</span> schedule</li>
					<li>Weekly personal <span className="highlighter">1:1 live sessions</span> with mentors</li>
					<li><span className="highlighter">Support platform</span> for code-related questions</li>
					<li>Community chat</li>
					<li>The course is taught in <span className="highlighter">English</span></li>
					<li>An industry-recognized <strong className="highlighter">graduation certificate</strong> upon completion</li>
					<li>Job support</li>
				</ul>
			</article>



			<article className="transparentContainer flex450">
				<h2>What you'll learn</h2>
				<div className='whiteCard'>
					<CodingLogos />
					<ul>
						<li>How to build fast, modern, dynamic and responsive <span className="highlighter">web apps</span></li>
						<li>Building native <span className="highlighter">mobile apps</span> for iOS and Android</li>
						<li><span className="highlighter">HTML, CSS, JavaScript, Node, Express, MongoDB, React.js, TypeScript, React Native, Git, UX basics</span></li>
						<li>Back-end, front-end and full-stack development</li>
						<li>Using databases</li>
						<li>Using external APIs</li>
						<li>Version control with Git and GitLab/GitHub</li>
						<li><span className="highlighter">Deployment</span> of your web apps with Linux and nginx</li>
						<li>Best web development practices and advanced techniques</li>
						<li><span className="highlighter">The only school that teaches back-end, front-end and native mobile apps development</span></li>
						<li>The most in-demand tools in the job market</li>
					</ul>
				</div>
			</article>



			<article className="transparentContainer flex450">
				<h2>The outcomes</h2>
				<div className='whiteCard'>
					<ul>
						<li><span className="highlighter">Get hired as a developer</span>. Most of our bootcamp graduates who finished the bootcamps successfully and were looking for a job got hired in first 3 months after the course. Become qualified for a junior developer position and start your career as a developer.</li>
						<li><span className="highlighter">Start freelancing</span>. Get the practical skills you need to start working remotely in the in-demand field of web/mobile development. A solid portfolio with your own completed apps and our personalised guidance on preparing for a new career.</li>
						<li><span className="highlighter">Build your own apps</span>. Got a great idea but not quite sure how to build it? Tackle the development yourself and acquire lifelong skills in the process. Once your project takes off, you will also be able to hire, test, and manage other coders.</li>
						<li>Take a look at some of the <span className="highlighter"> <a href="https://projects.barcelonacodeschool.com/" target="_blank" >students projects</a></span> built during the bootcamp.</li>
					</ul>
				</div>
			</article>







			<article className="transparentContainer flex450">
				<h2>How does it work</h2>
				<div className='whiteCard'>
					<ol>
						<li>Choose a starting date and sign up from this page to reserve your place</li>
						<li>Receive the pre-course materials to prepare before the bootcamp starts. We estimate it to take 40-80 hours if you start from absolutely zero level of knowledge of JavaScript, HTML and CSS</li>
						<li>Get all the main course materials, including text and video lessons</li>
						<li>From day one of the bootcamp start learning at your own pace extending the duration of the course if needed</li>
						<li>We provide support via support platform, chat, track you progress and give feedback</li>
						<li>Schedule weekly 1:1 sessions with your mentor to resolve any current issues, get extra explainations and ask any questions</li>
						<li>The curriculum, projects, exercises and the outcomes are identical with the in-person version of the bootcamp</li>
					</ol>
				</div>
			</article>


			<InternationalStudents />





			<article className="transparentContainer flex600">
				<h2>Tuition</h2>
				<div className='whiteCard'>
					<h3 style={{ 'marginBottom': '.5em' }}>2100€: 9 weeks, full-time</h3>
					<p>If you choose to do the course <strong>full-time</strong> and will be well-prepared you can finish it in <strong>9 weeks</strong>.</p>
					<h3 style={{ 'marginBottom': '.5em' }}>2500€: 13 weeks, part-time</h3>
					<p>If you are doing it in a <strong>slightly less intensive</strong> you will most likely complete it in <strong>13 weeks</strong> This is the most balanced option.</p>
					<h3 style={{ 'marginBottom': '.5em' }}>2900€: 16 weeks, part-time</h3>
					<p>For students combining the bootcamp with part-time or full-time <strong>job</strong> <strong>16 weeks</strong> are more realistic.</p>
					<details>
						<summary><h4 style={{ 'display': 'inline' }}>Optional weekly extensions available</h4></summary>

						<p>If you will need more time and mentors support to finish the bootcamp <strong>you can extend it on a weekly basis</strong> for 120€ per week which allows you to keep <strong>learning at your own pace</strong> while <strong>continuing getting all the support</strong> and guidance we provide.</p>
						<p>In this way you can complete the bootcamp according to your schedule and learning speed – from full-time intensive 9-week duration, which is the same as we do with in-person group on campus, to a longer timeframe.</p>
					</details>

					{/*<p className="note">You can “freeze” your course for 1 or 2 weeks in case of some personal circumstances. It means that you don’t have access to support from mentors (1:1 sessions, support platform, slack consultations) but you still keep all the materials. All you need to do is notify us at least 1 week before the desired date of suspending the course. </p>*/}
				</div>
			</article>

			<article className="transparentContainer flex600">
				<h2 className="">
					Access : start any Monday
					<span
						id="dates"
						style={{
							position: "relative",
							top: "-50",
							visibility: "hidden",
						}}
					>
						.
					</span>
				</h2>
				{/*<h4>Starting dates every week</h4>*/}
				{/*<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>*/}


				<div className='flexContent whiteCard flex300'>
					<div className="bottomSpace ">
						<span>
							<h2>{selected ? `You selected ${selected.toLocaleString("default", {
								month: "long",
							})} ${selected.getDate()}, ${selected.getFullYear()}` : 'Please choose your starting date'}</h2>
							<DayPicker
								mode="single"
								selected={selected}
								onSelect={setSelected}
								// footer={footer}
								weekStartsOn={1}
								// disabled={dayOfWeekMatcher}
								disabled={[disabledDays, dayOfWeekMatcher]}
								fromYear={today.getFullYear()}
								defaultMonth={new Date(today.setDate(today.getDate() + 14))}
							/>
						</span>
					</div>

					<div className="bottomSpace flex300">
						<h3>Web / Mobile Development Online Mentored Bootcamp</h3>
						<p>Weekly 1:1 sessions, support platform, chat channel, text/video lessons</p>
						<span>
							<p>Online, mentored, in English</p>
							{/*<p>{courses[0].fulldesc2}</p>*/}
							<p>9, 13 or 16 weeks, optional weekly extensions after that</p>
							<select value={duration} onChange={(e) => handleChange(e)} style={{ marginBottom: '1em', width: '100%' }}>
								<option value={null} disabled selected>Select the duration</option>
								<option value="2100">9 weeks, 2100€</option>
								<option value="2500">13 weeks, 2500€</option>
								<option value="2900">16 weeks, 2900€</option>
							</select>
							{selected && duration ? <Link
								to="/register"
								state={{
									course: 'Web / Mobile Development Online Mentored Bootcamp',
									date: selected && `${selected.toLocaleString("default", {
										month: "long",
									})} ${selected.getDate()}, ${selected.getFullYear()}`,
									cost: duration,
									cryptoID: cryptoCost,
									images: ['https://barcelonacodeschool.com/files/pics/web_development_online_bootcamp_barcelona_code_school.jpg'],
								}}
							>

								<button style={{ marginBottom: "1em" }}>Sign up</button> </Link> : <p className='highlighter'>Please choose a starting date and duration</p>}

						</span>
					</div>
				</div>

			</article>



			<JSProjectsExamples/>


			<section className="flex900 transparentContainer">
				<h2>Students stories</h2>
				<p>
					Check some of the reviews <a href="/about-us/testimonials/">here</a>
				</p>
				<div className="flexContent studentsStoriesShort">
					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Yen} />
						<h3>Yen Hoang</h3>
						<h4>Developer at Mind The Bridge</h4>
						<p>
							"I have had excellent teachers who are willing to guide
							me through my course. They are patient and kind.
							Moreover, I have fun when I code. It is the most
							important thing to me. I like it and I finally can
							engage myself to it."
						</p>
						<p>
							<a href="/students-outcomes/yen">Read full story</a>.
						</p>
					</div>

					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Carlos} />
						<h3>Carlos Planchart</h3>
						<h4>Front-end engineer at Purple Dye</h4>
						<p>
							"I was surprised at what I was able to accomplish in
							such a short time span. I enjoyed the learning process,
							and felt the course was crafted in such a way that
							subject transitions were smooth and made sense."
						</p>
						<p>
							<a href="/students-outcomes/carlos">Read full story</a>.
						</p>
					</div>

					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Diane} />
						<h3>Diane Serra</h3>
						<h4>UI Designer & Developer at Xrossworld</h4>
						<p>
							"They were so kind, responsive and encouraging. If you
							are considering this bootcamp, go to the school and meet
							George and Antonello. They will be your main source of
							support throughout the bootcamp."
						</p>
						<p>
							<a href="/students-story-diane-serra-designer-and-developer-at-Xrossworld">
								Read full story
							</a>
							.
						</p>
					</div>

					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Mike} />
						<h3>Mike Sullivan</h3>
						<h4>Developer at Green Power Monitor</h4>
						<p>
							"For me it was cool to learn something that I’m
							interested in from experts, it was also nice to know
							that if you didn’t understand something or needed
							clarification that you could just ask a question and get
							quite a detailed response".
						</p>
						<p>
							<a href="/students-outcomes/mike">Read full story</a>.
						</p>
					</div>
				</div>
			</section>




			<article className='transparentContainer flex450'>
				<video src={'https://barcelonacodeschool.com/files/videos/How_does_JavaScript_Full-Stack_Online_Bootcamp_in_Barcelona_Code_School_work.mp4'} width="100%" height="100%" controls autoPlay={false} muted={false} loop type="video/mp4" />
			</article>


			<article className="transparentContainer flex450">
				<h2>Why JavaScript?</h2>
				<div className='whiteCard'>
					<ul>
						<li>According to StackOverflow.com, the biggest community website for professional and enthusiast programmers, JavaScript has been <a href="https://survey.stackoverflow.co/2022/#programming-scripting-and-markup-languages" target='blank'> the world's most popular language for 10 years in a row </a>. </li>
						<li>Knowing JavaScript opens the door into web and mobile apps development without any limitations. Back-end, front-end, full-stack, iOS and Android apps development is all possible with JavaScript. </li>
						<li>Full-stack developer is <a href="https://barcelonacodeschool.com/why-learning-javascript-is-relevant-today"> the most in-demand role in the job market </a>. </li>
					</ul>
				</div>
			</article>

			<article className="transparentContainer flex450">
				<h2>Why Barcelona Code School?</h2>
				<div className='whiteCard'>
					<ul>
						<li><span className="highlighter">Cutting edge curriculum</span> tailored to job market and companies’ needs</li>
						<li>Instructors, who <span className="highlighter">know how to teach</span></li>
						<li>Fast track from beginner to job-ready coder</li>
						<li>Best students/mentors ratio across online bootcamps to allow personalized approach </li>
						<li><span className="highlighter">Job offers</span> for the students constantly coming from our <span className="highlighter">hiring partners</span></li>
						<li>Life-time career support</li>
					</ul>
				</div>
			</article>




			<article className='transparentContainer flex300'>
				<h2>Financing</h2>
				<div className='whiteCard'>
					<p>We can assist in getting a student loan which usually has lower rates than consumer loans.</p>
					<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
					<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>

					<Instalments />

				</div>
			</article>












			<article className='transparentContainer flex450'>
				<h2>FAQ</h2>
				<div className='whiteCard'>
					<details>
						<summary>
							<strong>What computer would I need?</strong>
						</summary>
						<p>
							Any operating system will do, Mac, Windows or Linux. If
							you are thinking about buying a new one then the best
							choice would be an Apple laptop: Macbook, Macbook Air or
							Macbook Pro. On Windows laptop everything is doable as
							well, simply some things work slower on Windows but it's
							not going to prevent you from succeeding!
						</p>
					</details>

					<details>
						<summary>
							<strong>What is the language of this course?</strong>
						</summary>
						<p>English.</p>
					</details>

					<details>
						<summary>
							<strong>Do I have to speak Spanish?</strong>
						</summary>
						<p>
							For the course you do not need Spanish. Everybody at our
							school speak English. To feel comfortable in Barcelona
							Spanish would help but nowadays in most of the places
							local people do speak English.
						</p>
					</details>

					<details>
						<summary>
							<strong>What if I already know how to code?</strong>
						</summary>
						<p>
							Amazing! For students who advance faster or have some
							prior experience we have more challenging track with
							hardcore exercises and job opportunities upon
							graduation.
						</p>
					</details>

					<details>
						<summary>
							<strong>
								What qualification I will get from this course?
							</strong>
						</summary>
						<p>
							If completed successfully you will become a junior
							full-stack developer. Meaning you will know how to build
							web apps and be able to apply for the entry developer's
							position.
						</p>
					</details>


					<details>
						<summary>
							<strong>
								How good would a typical graduate be after
								completing the course?
							</strong>
						</summary>
						<p>
							You will learn the skills to begin as a junior
							full-stack developer, will know how to build fully
							functional web apps and have a solid foundation to
							improve your expertise.
						</p>
					</details>

					<details>
						<summary>
							<strong>Is there a pre-course?</strong>
						</summary>
						<p>Yes. We send you materials to study to come prepared!</p>
					</details>
				</div>
			</article>


			<div className='flex450'>
				<Contacts
					title={"Inquiry about the course"}
					subject={"Inquiry about JS online bootcamp"}
				/>
			</div>

			<article className='transparentContainer flex900'>
				<h2>Some of the companies where our graduates work or worked</h2>
				<div>
					<PartnersLogos />
				</div>
			</article>

		</div>
	)
}

export default JSBootcampOnline
