import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Badges from '../../components/badges.js'

const Testimonials = () => (
	<div className="">
	<h1 className='transparentContainer'>Check out our reviews on Course Report and Switchup</h1>
	<Badges />

<article className='whiteCard bottomSpace'>
<p className="testimony">
"I enjoyed the learning process, and felt the course was crafted in
such a way that subject transitions were smooth and made sense. I
set out to build an actual multi-component project, and my project
exceeded my initial expectations. I was surprised at what I was able
to accomplish in such a short time span. What helped me not to give
up was the he mentorship from George and Stefano, and just
persistence and faith with the process in that eventually I will
understand what I am working on."
</p>
<p className="author">— <a href="http://www.carlosplanchart.com">Carlos</a></p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"The bootcamp was a journey to overcome a lot of obstacles and to
succeed. It was by far the best decision what I made. It brought me
a different point of view on how to approach a problem not only
code-wise but also in life."
</p>
<p className="author">— <a href="https://www.linkedin.com/in/anthony-tjon-a-san/">Tony</a>
</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"I would say that the course definitly changed my way of thinking,
and this became more apparent in the period after the course, where
the training wheels fell of so to speak, and I started coding on my
own, something that seemed like a real challenge when I was still
attending the class, but the good research habits I learned during
the course, and my instructors always being ready to give detailed
and fast help even until now have made a seemless transition."
</p>
<p className="author">— <a href="http://kdaou.surge.sh">Karim</a>
</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"...Additionally, the Barcelona Code School program is legit. And
the price point can't be beat. You will learn the top coding
languages to date and once you put those on your resume and LinkedIn
you will have recruiters calling you. They teach a really great
course, I know this because I compared them with similar bootcamps
in Barcelona but also in California. Best of luck!"
</p>
<p className="author">
<a href="https://www.dianeserra.com/blog/web-developer-bootcamp-qampa">
— Diane
</a>
</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"Barcelona Code School has a great curriculum and structure. The
teachers are definitely the most incredible assets. They complete
each other, allowing you to grow in many ways. You are able to learn
in every step of the process, as much as you want, they are there
for you, they are very inspiring and supportive. They give you a
	personalized treatment, respecting your background, your pace and
your goals.
</p>
<p className="testimony">
The atmosphere is cozy, integrative and fun. The batches have small
groups of students which allow us to connect easily, supporting each
other and expanding our network. There we are also able to exchange
experiences with the UX / UI designers, which is a plus. I had a great
time. I am very thankful! Learn JavaScript is not easy at all (at
	least not for me and my sociological mind! ), but with this guys,
this challenging journey can also be fun! Definitely worth it! I
highly recommend it!"
</p>
<p className="author">— Eveline Gama Rojas</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"I took the Full Stack JavaScript Bootcamp to understand better what
my partners were doing at work. I knew it was going to be a
challenge, it's a lot to be taught in such short period, and I
wasn't excited about it at all. Happened that through this school I
became fond of programming, and now I feel like working with it too.
I wish I had done this before.
</p>
<p className="testimony">
The course goes very fast, as expected. Most of the time we were
doing exercises and that gave me a certain fluency in programming
even I was surprised about. Being frustrated sometimes was
inevitable so I gave up trying to be good at anything while
learning, I just took notes and tried to absorb as much information
as possible in that period. Do not think they will explain every
single detail, there's no time for that. Somethings just work, I
took notes and reviewed it later by myself on my time - every night
and weekend. The extra effort definitely paid off, I was
complimented in my exercises and even managed to help other
classmates.
</p>
<p className="testimony">
George and Stefano, my lecturers, have wide experience in teaching
international students. All explained in clear English and step by
step. Both made the perfect combination for me as a student. Super
George has many years of experience to share and Saint Stefano
understands well the student's point of view, they could find the
best way to help me get over my obstacles and grow.
</p>
<p className="testimony">
They keep on updating their material to better fit today's needs.
It's an unforgettable experience, amazing people, and the investment
pays off itself in no time. I learned enough to be able to start
working but also to continue learning by myself. The course
definitely reduces the struggle of learning coding."
</p>
<p className="author">— Rafael Secco</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"The course motto really caught my attention. It seemed a bit too
much, but definitely very bold.</p>
<p className="testimony">
It was intense, fast and I only noticed how much I had learned in
the last week when I was writing the code for my first Mobile App.
</p>
<p className="testimony">
I was in the Jan-Mar 2019 Full-stack JavaScript Bootcamp and coming
from a completely different background (I'm a bachelor in Sciences
	with major in Biomedical Sciences) I have to say that it was hard in
the beginning. I had to study a lot, including after classes and
during the weekends, but it is totally doable. The projects are
interesting, there are a lot of exercises to keep on training and
the support that they all gave me made a huge difference!
</p>
<p className="testimony">
Now, as a Junior Developer they are helping me with all that I need
to find an internship/job.
</p>
<p className="testimony">
I'm grateful for the awesome job that George, Antonello and
Margarita did and continuing doing."
</p>
<p className="author">— Thaisa</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
"I attended the bootcamp from January to March 2019. It was a great
experience where I learn from experienced people but I also had fun.
</p>
<p className="testimony">
Before starting I didn't know how the course would be but it
surprised me how much I learn in just 9 weeks.{" "}
</p>
<p className="testimony">
What motivated me to choose the school was the curriculum they offer
with the possibility to learn not only about web apps but also
mobile apps.
</p>
<p className="testimony">
If you are starting the bootcamp you should know that it is a tough
course because you learn a lot in such a short time and you have to
work hard doing all the projects and exercises but then you will be
happy with all you achieve.{" "}
</p>
<p className="testimony">
Sometimes when we learn new things I was concerned because it was
too much information to work with and I felt like I didn't
understand the point but then in the practice time I realised that I
got all the concepts and I was able to work with it. Also we had a
great support from the teachers.
</p>
<p className="testimony">
We attended different activities as a group and we were invited to
4YFN and Mobile Congress which was a huge experience.
</p>
<p className="testimony">
George, Antonello and Margarita had done a really good job!"
</p>
<p className="author">— Luciana</p>
</article>

<article className='whiteCard bottomSpace'>
<p className="testimony">
I attended Barcelona Code School, Aug - Oct 2018 and was very happy
with the experience. I had previous programming and
Javascript,HTML,CSS experience, and had been trying to teach myself
web development. Because of this I had been slightly concerned that
these sections would move too slowly for me. However, with the video
lectures to supplement the live lectures, I could go at my own
speed. I also found that, even though I knew a lot of the material,
the 8 hours a day of class was amazing ‘practice, practice,
practice’. I felt much more solid afterwards and certainly had some
knowledge holes filled in. Having the experienced teachers helped me
learn fast. I was new to backend programming and even though there
was a lot of material, I felt like I came out at a much more
experienced level. There’s still a LOT to know and a lot MORE to
learn, but that is the nature of this industry – so get used to it!
</p>
<p className="testimony">
I chose Barcelona Code School because they had a focus on REACT and
REACT-native. (I live in Barcelona) I wanted to learn these because
they are the IN-DEMAND skills right now in Boston (my previous home)
– plus I knew they were pretty cool. I had tried to teach myself
REACT too, so I had a start, but there’s nothing like having a
teacher. The other code camps say they have a one evening special
workshop in REACT if people are interested, but how could that be
enough? The course is taught all in English and the teachers were
very helpful in answering all my questions so that I understood the
concepts and techniques.
</p>
<p className="testimony">
I had been thinking about code school for many years, but the cost
of any code school seemed high. I tried to teach myself, and plodded
along, sometimes getting blocked for days on what I knew were stupid
problems that could be solved by someone with understanding in 5
seconds. Then this year, I watched some kids graduating from
university and realized that they hadn’t tried to ‘teach themselves
BIOLOGY’. You could do it, but it would take 100 times longer.
Teachers are important! So I finally decided to enroll. I wish I had
done code camp 5 years ago. I would have been working all these
years making up the tuition cost in a month or 2 (I was in the USA).
</p>
<p className="testimony">
Barcelona Code School was perfect for me in that it wasn’t too long
and wasn’t too expensive.{" "}
</p>
<p className="testimony">
Plus, I love having access to the videos and class notes, so I can
go back and look things up and review. (Which I needed to do to take
	lots of job application technical tests)
</p>
<p className="testimony">
After the course was done, the school sent my name out to recruiters
and I got LOTS of calls. I have had many interviews – and have some
good possibilities. I am currently in serious talks with 3 excellent
companies.
</p>
<p className="testimony">
So, I am very happy with my decision to go to BCS.
</p>
<p className="author">— Christine</p>
</article>
</div>
)

export default Testimonials
