import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
import './studentOutcome.css'

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../../components/checkout';


import Carlos from '../../pics/students/carlos_planchart.jpg'


const CarlosStory = () => (


	<div className="white grid-1-3 studentoutcome"
	>
	<Helmet

	title="Student's story: Carlos Planchart, a front-end engineer at Purple Dye"
	meta={[
		{ name: 'description', content: 'I enjoyed the learning process, and felt the course was crafted in such a way that subject transitions were smooth and made sense.' },
		{ name: 'keywords', content: 'Students story, Barcelona Code School' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
		{property: "og:title", content:"Student's story: Carlos Planchart, a front-end engineer at Purple Dye"},
		{property: "og:description", content: "I enjoyed the learning process, and felt the course was crafted in such a way that subject transitions were smooth and made sense."},
		{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/students/carlos_planchart.jpg"},
		{property: "og:url", content: "https://barcelonacodeschool.com/students-outcomes/carlos"},
		{name: "twitter:card", content: "summary_large_image"},
		]}
		/>
		<div>
		<h1>Student's story: Carlos Planchart</h1>
		<h2>Graduated from the <a href='/barcelona-code-school/javascript-full-stack-online-bootcamp/'>JavaScript Full-Stack Online Bootcamp</a></h2>
		<h2>Job landed: front-end engineer at Purple Dye</h2>
		</div>
		<div style={{
			marginTop: '2em'
		}}>

		<img src={Carlos} />


		<p>I worked in architecture professionally for seven years, where I indirectly saw the power of coding through the software we used on a daily basis. Increasingly, I wanted to be able to create my own software tools rather than complete the manual work we do in architecture. When I wrote my first bits of code that actually output something visually, that was the first exciting moment where I thought, “Wow! I need to do more of this!”</p>

		<p>As anyone who is familiar with the architecture industry, deadlines can absorb all your time. I began self-studying through resources like Free Code Camp and Code Academy, but eventually my work commitments derailed my coding learning progress. So I thought that I really needed to devote consistent focused training to be able to output more than a few lines of code. This led me to consider Bootcamps.</p>

		<p>I was skeptical at first about coding Bootcamps, because I thought they promise too much given the very low time span. But like in any industry, good mentors will help you immensely, and it is crucial to have other professionals validate your work. I received this at Barcelona Code School as well as a more personalized curriculum, and finally I was able to develop and deploy my first fully-functioning web-application.</p>

		<p><blockquote>"I enjoyed the learning process, and felt the course was crafted in such a way that subject transitions were smooth and made sense."</blockquote> </p>

		<p>The learning was of course fast-paced, and quite challenging. New frameworks and technologies were thrown at me on a seemingly weekly basis, and I sometimes felt I wouldn’t be able to complete all the necessary assignments. But George and Stefano are there to work with you, and their mentorship was crucial to help stir me back on the right direction. When you are learning sometimes you are your own worst enemy and critic, and it is good to have outside support to validate your efforts.</p>

		<p><blockquote>“I set out to build an actual multi-component project, and my project exceeded my initial expectations. I was surprised at what I was able to accomplish in such a short time span. "</blockquote> </p>

		<p>Once the bootcamp ended of course the learning didn’t stop, and I realized it will never end. Ultimately, the bootcamp gave me a solid foundation to be able to absorb new frameworks or coding languages professionally. I am now learning new front-end and back-end tools, and recognize that they are already built on the solid foundations I received at BCS.</p>

		<p>I currently work remotely for Purple Dye as a front-end engineer. Upon completion of the bootcamp, we were still in the height of the global pandemic and of course I was concerned with job prospects with my non-traditional background. Yet, thankfully, my concerns were ultimately unfounded, and BCS worked to help spread my online profile to hiring partners. I now develop front-end components using Svelte and Typescript that interface with custom back-end APIs. The web site components are completed in tandem with the UX / UI designer.</p>

		<p>Interestingly enough this creative and technical work resembles a lot of the challenges I faced in the architecture industry. I went from physical space making to crafting digital spaces in this exciting new industry, and I am grateful and happy to be where I am now.</p>



		<p><blockquote>"What helped me not to give up was the he mentorship from George and Stefano, and just persistence and faith with the process in that eventually I will understand what I am working on."</blockquote> </p>

		<p>If I could do one thing differently before starting this journey I would have started earlier building actual small Javascript projects before the Bootcamp. This was the biggest hurdle to overcome: moving from completing javascript challenges to actually building a full-scale project. Also I would pay closer attention to the nuances of CSS.</p>

		<p>My advice to the future BCS's students: continue coding and have fun with the process. Explore what motivates you, and there are many people in the tech. industry willing to help you get to where you want to get into. Also don't feel intimidated, if you feel you fall short in your programming knowledge. Web development is a continual learning process, and employers care more that you are more open to continual learning and growth than if you know all the new latest languages or frameworks.</p>


		</div>
		</div>


		)

export default CarlosStory



