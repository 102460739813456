import React from 'react'
import { Link } from 'gatsby'
import Helmet from "react-helmet";

import Contacts from './contacts'

import CorpTraining from '../pics/corporate-training_barcelona-code-school-jikken-kobo.png'




const Corporate = () => {
	return <div className="flexContent">
	<Helmet
	title='Corporate training in Barcelona Code School'
	meta={[
		{name: 'charset', content: 'UTF-8'}, 
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
		{name: 'og:locale', content: 'en_US' },
		{ name: 'description', content: 'Corporate training in Barcelona Code School. Upgrade your team with new skills for improved effectiveness. Learn web development, mobile development, UX / UI design, JavaScript programming, Node, Express, MongoDB, React, React Native. Custom tailored courses for companies in Barcelona or online.' },
		{ name: 'keywords', content: 'corporate training in Barcelona, custom courses for companies' },
		{property: "og:title", content:"Corporate training in Barcelona Code School"},
		{property: "og:description", content: "Corporate training in Barcelona Code School. Upgrade your team with new skills for improved effectiveness. Learn web development, mobile development, UX / UI design, JavaScript programming, Node, Express, MongoDB, React, React Native. Custom tailored courses for companies in Barcelona or online."},
		{property: "og:image", content: "https://barcelonacodeschool.com/files/pics/corporate-training_barcelona-code-school-jikken-kobo.png"},
		{property: "og:url", content: "https://barcelonacodeschool.com/corporate-training/"},
		{ name: "twitter:card", content: "summary_large_image" }
		]}

	script={[
		{ "src": "https://js.stripe.com/v3/" }
		]}
	/>
	<h1 className='transparentContainer flex225'><span className='highlighter'>Corporate training and services</span></h1>

	<div className='transparentContainer flex450'>
	<h2 >Custom workshops and courses to upgrade your team</h2>
	<div className='whiteCard'>
	<ul>
	<li>Web develoment with JavaScript, Node, Express, React.js, HTML, CSS</li>
	<li>Mobile develoment with React Native</li>
	<li>Any aspects of UX (user experience), UI (user interace), visual design for web and mobile</li>
	</ul>
	</div>
	</div>

	<div className='transparentContainer flex450'>
	<h2>Production services</h2>
	<div className='whiteCard'>	
	<ul>
	<li>Web development: websites and web applications of any complexity</li>
	<li>Mobile apps development for iOS and Android</li>
	<li>User experience works: user research, user testing, prototyping, validation, ideation, analysis, etc...</li>
	<li>UI design, visual design, style guides, logo design, etc...</li>
	<li>IT consulting</li>
	</ul>
	</div>
	</div>



	<div className='transparentContainer flex225'>
	<p className='whiteCard'>At Barcelona Code School we have accumulated immense amount of knowledge and practical experience while hosting classes for private students. Now is the time we are ready to offer something for the corporate clients.</p>
	</div>
	<div className='transparentContainer flex225'>
	<p className='whiteCard'>Customised corporate training workshops and courses can help your employees to gain new skills, competences and knowledge which will help them to increase effectiveness of doing daily tasks or advance to some new level of responsibilities and abilities. We know how to use all the latest and most amazing tools and technologies and we can teach you!</p>
	</div>

	<div className='transparentContainer flex225'>
	<p className='whiteCard'>Just let us know what areas you are interested in and we will create a unique custom workshop to address the challenges you are facing. We can host classes at yours or our facilities and choose English or Spanish language.</p>
	</div>
	<div className='transparentContainer flex225'>
	<p className='whiteCard'>Please contact us for more details.</p>
	</div>
	<article className='flex900'>
	<Contacts title={'Send inquiry'} subject={'Inquiry about corporate training'} />
	</article>

	</div>
}

export default Corporate
